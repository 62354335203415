<template>
  <v-app>
    <div>
      <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
            <h3>Timesheet Report</h3>
        </div>
        <div class="col-md-12 d-flex justify-start" >
          <v-row no-gutters>
            <div class="col-md-4">
              <SelectField :items="salaryCycles" v-model="formData.salaryCycle" :label="'Salary Cycle'" :itemText="'description'" :itemValue="'id'" :returnObject="true"/>
            </div>
            <div class="col-md-3">
              <TextField v-model="refPayment" :label="'Payment Ref.'" />
            </div>
            <div>
              <Button :btnType="'Submit'" :isLoading="isSubmit" :label="'Submit'" @onClick="onSubmit" class="col-md-3"/>
            </div>
          </v-row>

          <!-- <v-row no-gutters>
            <v-col>
              <SelectField :items="approvalStatus" v-model="formData.status" :label="'Timesheet Status'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="selectedStatus" />
            </v-col>
          </v-row> -->
        </div>
        <!-- <p>{{personnel}}</p> -->
      </div>
    </div>

    <!-- timesheet calendar  -->

    <div class="scroll row">
      <div class="col-md-10 col-sm-12 ml-auto mr-auto">
        <v-col class="col-md-11 col-sm-12 mt-5 d-flex justify-end">
          <div class="download-type">
            <SelectField :items="downloadFormat" v-model="formData.selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
          </div>
          <!-- <Button :label="'Download'" :btnType="'Submit'" @onClick="onDownload" :isLoading="isTimesheet" class="" /> -->
          <Button :btnType="'Submit'" :isLoading="isDownloading" :label="'Download'" @onClick="onDownload" class="col-md-3 mr-2"/>
        </v-col>
        <v-text-field v-model="searchTable" placeholder="Search......" outlined dense />
        <div v-if="loadingData" class="text-center">
          <v-progress-circular indeterminate
                               color="primary"></v-progress-circular>

        </div>
        <v-simple-table>

          <template v-slot:default>
            <thead>
              <tr>                
                <th>Personnel</th>
                <th>Company</th>
                <th>Salary Cycle</th>
                <th>Approval Status</th>
                <th>Payment Reference</th>
                <th>Summary</th>
                <!-- <th v-for="item in headers" :key="item.headerName">{{item.headerName}}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="timesheet in filterTimesheets" :key="timesheet.employeeId">
                <td>
                  {{timesheet.name}}
                </td>
                 <td>
                  {{timesheet.company}}
                </td>
                <td>
                  {{timesheet.salaryCycle}}
                </td>
                <td>
                  {{timesheet.approvalStatus}}
                </td>
                <td>
                  {{timesheet.PaymentRef}}
                </td>
                <td>
                  {{timesheet.regularDays}}
                </td>

               <!-- <td>
                  {{timesheet.regularDays}}
                </td>
                <td>
                  {{timesheet.regularDays}}
                </td> -->
                <!-- <td v-for="(item, index) in timesheet.timesheetItems"
                    :key="item.timesheetItemDate"
                    v-bind:class="timesheet.timesheetItems[index].type">
                  <span v-if="timesheet.timesheetItems[index].show === true"> -->
                    <!-- <b>Work Hrs</b> -->
                    <!-- <b>{{timesheet.timesheetItems[index].hoursWorked}}</b> -->
                    <!-- <input disabled title="Work Hours" type="Number" min="0" max="timesheet.rotationHours" class="hour-input" v-model="timesheet.timesheetItems[index].hoursWorked" /> -->
                  <!-- </span>
                  <span v-if="timesheet.timesheetItems[index].show === false">
                    <b>{{timesheet.timesheetItems[index].type}}</b>
                  </span>
                </td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <table hidden id="timesheetTable">
          <thead>
            <tr>
              <!-- <th>Name</th>
              <th>Appr. Status</th>
              <th>Summary</th>
              <th v-for="item in headers" :key="item.headerDay">{{item.headerDay}}</th> -->
              <th>Personnel</th>
              <th>Company</th>
              <th>Salary Cycle</th>
              <th>Approval Status</th>
              <th>Payment Reference</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="timesheet in filterTimesheets" :key="timesheet.employeeId">
              <!-- <td>
                {{timesheet.name}}
              </td>
              <td>
                {{timesheet.approvalStatus}}
              </td>
              <td>
                {{timesheet.regularDays}}
              </td>
              <td v-for="(item, index) in timesheet.timesheetItems"
                  :key="item.timesheetItemDate"
                  v-bind:class="timesheet.timesheetItems[index].type">
                <span v-if="timesheet.timesheetItems[index].show === true"> -->
                  <!-- <b>Work Hrs</b> -->
                  <!-- <b>{{timesheet.timesheetItems[index].hoursWorked}}</b> -->
                  <!-- <input disabled title="Work Hours" type="Number" min="0" max="timesheet.rotationHours" class="hour-input" v-model="timesheet.timesheetItems[index].hoursWorked" /> -->
                <!-- </span>
                <span v-if="timesheet.timesheetItems[index].show === false">
                  <b>{{timesheet.timesheetItems[index].typeShortCode}}</b>
                </span>
              </td> -->             
                 <td>
                  {{timesheet.name}}
                </td>
                 <td>
                  {{timesheet.company}}
                </td>
                <td>
                  {{timesheet.salaryCycle}}
                </td>
                <td>
                  {{timesheet.approvalStatus}}
                </td>
                <td>
                  {{timesheet.PaymentRef}}
                </td>
                <td>
                  {{timesheet.regularDays}}
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <v-row>
      <!-- <v-row v-if="showDownload"> -->

      </v-row>
  </v-app>
</template>

<script>
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import { salaryPeriodService, timesheetService } from '@/services'
import moment from 'moment'
/*import XLSX from 'xlsx'*/
import * as XLSX from 'xlsx/xlsx.mjs';
import JSPDF from 'jspdf'
import 'jspdf-autotable'
import { ROTATION, TimesheetShortCodes } from '@/utils/constants'

export default {
  components: {
    SelectField,
    Button,
    TextField
  },
  props: ['value'],
  data () {
    return {
      loadingData: false,
      isDownloading: false,
      isSubmit: false,
      searchTable: '',
      employeeName: '',
      refPayment: '',
      timeSheetDetail: {},
      selectedPersonnel: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      showDownload: false,
      salaryCycleId: null,
      statusId: null,
      formData: {
        requestId: '',
        personnel: '',
        salaryCycle: {}
      },
      search: '',
      personnelSearch: [],
      requestType: [],
      salaryCycles: [],
      personnel: [],
      selected: [],
      headers: [],
      timeSheets: [],
      filterTimesheets: [],
      searching: false,
      creating: false,
      searchHeaders: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'department',
          value: 'departmentName'
        },
        {
          text: 'rotation',
          value: 'rotationName'
        }
      ],
      personnelHeaders: [
        {
          glutenfree: true,
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      approvalStatus: [
        {
          id: 0,
          name: 'Pending'
        },
        {
          id: 1,
          name: 'Approved'
        },
        {
          id: 2,
          name: 'Rejected'
        },
        {
          id: 3,
          name: 'Recycle'
        },
        {
          id: 4,
          name: 'Recall'
        }
      ],
      downloadFormat: [
        {
          name: 'Excel',
          id: 'excel'
        },
        {
          name: 'PDF',
          id: 'pdf'
        }
      ],
      selectedFormat: ''
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    generateTimesheet (data) {
      let dateArr = []
      this.headers = []
      this.timeSheets = []
      const prevDate = moment(this.formData.salaryCycle.startDate).format('MM-DD-YYYY')
      const nextDate = moment(this.formData.salaryCycle.endDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start <= end) {
        dateArr.push({
          headerName: moment(start).format('ddd, MMM D'),
          date: moment(start).format('MM-DD-YYYY'),
          headerDay: moment(start).format('DD')
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      this.headers = dateArr
      this.createTimesheetTable(data)
    },
    // selectedCycle (salaryData) {
    //   this.filterTimesheets = []
    //   this.salaryCycleId = salaryData.id
    //   this.generateTimesheet()
    // },
    // selectedStatus (status) {
    //   this.filterTimesheets = []
    //   this.statusId = status.id
    //   if (this.salaryCycleId !== null) {
    //     this.generateTimesheet()
    //   }
    // },
    createTimesheetTable () {
      this.showDownload = false
      if (this.refPayment !== null) {
        this.loadingData = true
        const data = {
          SalaryPeriodId: this.formData.salaryCycle.id,
          PaymentReference: this.refPayment
        }
        timesheetService.getTimesheetReport(data).then(res => {
          const employeeTimesheets = res.data
          employeeTimesheets.forEach(timesheet => {
            const { employee, timesheetItems } = timesheet
            const rotationName = employee.rotation.name
            const newTimesheetItems = []

            this.headers.forEach(header => {
              const date = moment(header.date).format('MM-DD-YYYY')
              let timeSheetItem = timesheetItems.find(item => moment(item.timesheetItemDate).format('MM-DD-YYYY') === date)
              if (timeSheetItem !== undefined) {
                if (timeSheetItem.type === 'Holiday' || timeSheetItem.type === 'On Leave') {
                  timeSheetItem = Object.assign({}, timeSheetItem, { show: false })
                } else {
                  timeSheetItem = Object.assign({}, timeSheetItem, { show: true })
                }

                const typeShortCode = TimesheetShortCodes[timeSheetItem.type]
                timeSheetItem = Object.assign({}, timeSheetItem, { 'typeShortCode': typeShortCode })
                timeSheetItem.type = timeSheetItem.type === 'Holiday' ? 'holiday' : timeSheetItem.type
                newTimesheetItems.push(timeSheetItem)
              } else {
                newTimesheetItems.push({
                  timesheetId: timesheet.id,
                  timesheetItemDate: date,
                  hoursWorked: 0,
                  isRegular: false,
                  isOvertime: false,
                  isShift: false,
                  show: false,
                  type: rotationName === ROTATION.OfficeRegular ? 'weekend' : '0',
                  typeShortCode: rotationName === ROTATION.OfficeRegular ? 'W' : '0'
                })
              }
            })

            if (timesheet !== undefined) {
              const newTimesheet = {
                prnumber: employee.prnumber,
                timesheetId: timesheet.id,
                name: `${employee.lastName} ${employee.firstName} ${employee.middleName}`,
                employeeId: employee.id,
                email: employee.email,
                cai:employee.cai,
                company: employee.contractEmployer.name,
                rotation: rotationName,
                salaryPeriodId: this.salaryCycleId,
                timesheetItems: newTimesheetItems,
                approvalStatus: timesheet.approvalStatus,
                regularDays: timesheet.regularDays,
                salaryCycle: timesheet.salaryPeriod.description,
                PaymentRef: timesheet.paymentBatchNumber
              }

              this.timeSheets.push(newTimesheet)
            }

            console.log(this.timeSheets)

            this.filterTimesheets = this.timeSheets
            if (this.timeSheets.length > 0) {
              this.showDownload = true
            }
          })
        })
          .catch(() => {})
          .finally(() => {
            this.loadingData = false
          })
      }
    },
    onSubmit () {
      this.filterTimesheets = []
      this.generateTimesheet()
    },
    onDownload (e) {
      if (this.selectedFormat !== '') {
        this.isDownloading = true
        const time = moment().format()
        switch (this.selectedFormat) {
          case 'pdf':
            const doc = new JSPDF('landscape')
            doc.autoTable({
              theme: 'striped',
              headStyles: { fillColor: '#0B2D71', textColor: '#fff' },
              alternateRowStyles: { fillColor: '#F3F4F8' },
              html: '#timesheetTable'
            })

            // doc.text('Pending Timesheet', 150, 10, { align: 'center' })
            // doc.text('L - Leave', 150, 200, { align: 'center' })
            // const time = moment().format()
            doc.save(`Timesheet_${time}.pdf`)
            this.isDownloading = false
            break
          case 'excel':
            const formattedData = []
            this.filterTimesheets.forEach(item => {
              if (item.email == '') {
                item.email = item.cai + "@chevron.com"
                console.log(item.email)
              }
              formattedData.push({
                cai: item.cai,
                email: item.email,
                employeeName: item.name,
                company: item.company,
                salaryCycle: item.salaryCycle,
                approvalStatus: item.approvalStatus,
                PaymentRef: item.PaymentRef,
                summary: item.regularDays
              })
            })
            console.log(formattedData)
            var reportSheet = XLSX.utils.json_to_sheet(formattedData)
            var timeSheetWorkBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(timeSheetWorkBook, reportSheet, 'TimeSheetReport')
            XLSX.writeFile(timeSheetWorkBook, `TimeSheetReport${time}.xlsx`)
            this.isDownloading = false
            break
          default:
            break
        }
      }
    },
    onSelectedFormat (value) {
      this.selectedFormat = value.id
    },
    onSearch () {
      // console.log(e)
    },
    openPersonnelSearchModal () {
      this.search = ''
      this.$refs.personnelSearch.openModal()
    },
    onReset () {
      this.$refs.salarycomponent.reset()
    }
  },
  mounted () {
    salaryPeriodService.getAllSalaryPeriod().then(result => {
      this.salaryCycles = result.data
    })
  },
  watch: {
    searchTable: function (val) {
      if (this.timeSheets.length > 0) {
        if (val !== '') {
          const filtered = []
          this.timeSheets.forEach(item => {
            const itemExist = item.name.toUpperCase().includes(val.toUpperCase())
            if (itemExist) filtered.push(item)
          })

          this.filterTimesheets = filtered
        } else {
          this.filterTimesheets = this.timeSheets
        }
      }
    }
  }
}

</script>

<style scoped>
  .download-type {
    margin-right: 10px;
    padding-right: 2px;
    /* width: 50px; */
  }

  .holiday {
    background-color: orange;
    color: #fff;
  }

  .offday, .weekend {
    background-color: maroon;
    border-right: 1px solid #fff;
    color: #fff;
  }

  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-end;
  }

  .hour-input {
      border: 1px rgb(156, 154, 154) solid;
      width: 50px;
      padding: 1px;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
